@use "../utils" as *;

.rp-project-item {
  padding: 0;
  width: 100%;
  display: block;
  overflow: hidden;
  position: relative;

  // &:hover {
  //   & > .thumb {
  //     & > .shadow {
  //       & > .description {
  //         @include transform(translateY(0));
  //       }
  //     }
  //   }
  // }

  &:before {
    content: "";
    display: block;
    padding-top: 56.25%;
  }

  & > .thumb {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    @include border-radius(inherit);

    & > .shadow {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      position: absolute;
      background: linear-gradient(360deg, rgba(50, 50, 50, 0.8), transparent);

      & > .title {
        position: absolute;
        left: $default-distance;
        top: $default-distance * 0.5;
      }

      & > .icon {
        top: $default-distance;
        right: $default-distance;
        position: absolute;
      }

      & > .description {
        bottom: 0;
        position: absolute;
        padding: $default-distance * 0.75;
        // @include transform(translateY(100%));
        @include transition(transform 300ms ease-in-out);
      }
    }

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: contain;
      object-position: center;
    }
  }
}
