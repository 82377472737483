@use "../utils" as *;

main {
  padding-top: $default-distance * 2;

  section {
    width: 100%;
    min-height: 100%;

    &.section-repo,
    &.section-intro,
    &.section-history,
    &.section-project,
    &.section-overview,
    &.section-tech-stack {
      overflow: hidden;
      position: relative;
    }
  }

  #intro {
    .intro-container {
      & > .content {
        & > .social {
          margin-right: auto;
          margin-top: $default-distance * 2;
        }
      }
    }
  }

  #overview {
    .overview-container {
      & > .content {
        ul {
          li {
            list-style: square;
          }
        }
      }
    }
  }

  #repo,
  #intro,
  #project,
  #history,
  #overview,
  header > *,
  footer > *,
  #tech_arsenal {
    width: 100%;
    padding: 0 $default-distance-md;
    margin-bottom: $default-distance-xl;

    & > .section-header {
      margin: $default-distance-xl 0;

      & > * {
        text-transform: uppercase;
        @include border-radius($border-radius);
        padding: $default-distance * 0.5 $default-distance * 1.25;
      }
    }

    & > .section-content {
      & > .repo-container,
      & > .history-container,
      & > .project-container,
      & > .overview-container,
      & > .tech-stack-container {
        width: 100%;

        & > .content {
          width: 100%;
        }
      }
    }
  }

  header > * {
    margin: 0;
  }
}

@media (min-width: $media-xl) {
  main {
    #repo,
    #intro,
    #history,
    #project,
    #overview,
    footer > *,
    header > *,
    #tech_arsenal {
      padding: 0;
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
